/* Setoran Mandiri Store */

import SetoranMandiriListVM from "../../models/setoranMandiriList";


const listVM = new SetoranMandiriListVM();

// Mutations
const setRefreshRequest = (requestBy: string): void => {
  listVM.setRefreshRequest(requestBy);
};

const resetState = (): void => {
  listVM.reset();
};

export {
  listVM,
  resetState,
  setRefreshRequest,
};
