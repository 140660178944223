/* Pinjaman Router */

import { RouteConfig } from "vue-router";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";
import APP_CONFIG from "@/apps/core/modules/config";

const baseName = "pengelola";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "home",
        name: `${baseName}-home`,
        component: () =>
          import(/* webpackChunkName: "pengelola" */ "../views/PengelolaHome.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["pinjaman.view_pinjaman"],
        },
      },
      {
        path: "me",
        name: `${baseName}-me`,
        component: () =>
          import(/* webpackChunkName: "pengelola" */ "../views/PengelolaMe.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["pengelola.view_pengelola"],
        },
      },
      {
        path: APP_CONFIG.idURLPattern,
        name: `${baseName}-detail`,
        component: () =>
          import(/* webpackChunkName: "pengelola" */ "../views/PengelolaMe.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["pengelola.view_pengelola"],
        },
      },
      {
        path: "add",
        name: `${baseName}-add`,
        props: { headerText: "Tambah Pengelola" },
        component: () =>
          import(/* webpackChunkName: "pengelola" */ "../views/PengelolaForm.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["pengelola.add_pengelola"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-change`,
        props: { headerText: "Ubah Pengelola" },
        component: () =>
          import(/* webpackChunkName: "pengelola" */ "../views/PengelolaForm.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["pengelola.change_pengelola"],
        },
      },
      {
        path: "list",
        name: "anggota-list",
        component: () =>
          import(/* webpackChunkName: "anggota" */ "../views/AnggotaList.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["nasabah.view_nasabah", "pengelola.view_pengelola"],
        },
      },
    ],
  },
];

export default routes;
