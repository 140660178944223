import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "root",
    redirect: { name: "base-home" },
  },
  {
    path: "/bantuan",
    name: "bantuan",
    component: () =>
      import(/* webpackChunkName: "core" */ "../views/Bantuan.vue"),
    meta: {
      groups: ["admin", "pengelola", "nasabah"],
      permissions: ["core.view_appuser"],
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "core" */ "../views/About.vue"),
    meta: { status: "about" },
  },
];

export default routes;
