import { snakeCase } from "lodash";
import { ToastProgrammatic as Toast } from "buefy";
import Vue from "vue";
import APP_CONFIG from "../modules/config";
import ListAPI from "../api/list";
import PlainModel from "./plainModel";

export class RefreshRequest {
  requestBy = "";
  filterMap: Record<string, string> | undefined;

  setRefreshRequest(requestBy: string, filterMap?: Record<string, string>): void {
    this.requestBy = requestBy;
    this.filterMap = filterMap;
  }

  resetRefreshRequest = (): void => this.setRefreshRequest("");
}

export class Pagination {
  total = 0;
  limit = 0;
  page = 1;
  prevPage = 0;
  nextPage = 0;
  startRowNum = 0;
  show = true;

  setPagination(total: number): void {
    this.total = total;
    this.startRowNum = (this.page - 1) * this.limit + 1;
    this.show = this.total > this.limit;
  }

  reset(): void {
    this.total = 0;
    this.limit = 0;
    this.page = 1;
    this.prevPage = 0;
    this.nextPage = 0;
    this.startRowNum = 0;
    this.show = true;
  }
}

export default abstract class ViewListModel {
  // Tidak bisa menggunakan Generic, karena di js tdk bisa
  // bikin new instance menggunakan Generic.
  listAPI: ListAPI;
  list: Array<any> = [];
  idSet: Set<string> = new Set();
  pagination = new Pagination();
  isLoading = false;
  filterMap: Record<string, any> = { q: "" };
  filterPrevMap: Record<string, string> = {};
  infiniteScroll: boolean;
  refreshRequest: RefreshRequest;
  filtered = false;

  constructor(
    listAPI: ListAPI,
    refreshRequest = new RefreshRequest(),
    infiniteScroll = true
  ) {
    this.listAPI = listAPI;
    this.refreshRequest = refreshRequest;
    this.infiniteScroll = infiniteScroll;
  }

  addFilterMap(filterMap: Record<string, string>): void {
    Object.assign(this.filterMap, filterMap);
  }

  getList = (): Array<any> => this.list;

  getRefreshRequest = (): string => this.refreshRequest.requestBy;

  setRefreshRequest(requestBy: string, filterMap?: Record<string, string>): void {
    this.refreshRequest.setRefreshRequest(requestBy, filterMap);
  }

  resetRefreshRequest = (): void => this.refreshRequest.resetRefreshRequest();

  setPagination(pagination: Record<string, any>): void {
    this.pagination.setPagination(pagination.total);
  }

  private toSnakeCaseObject(json: Record<string, any>): Record<string, any> {
    const obj: Record<string, any> = {};
    for (const [field, value] of Object.entries(json)) {
      if (value !== "") {
        obj[snakeCase(field)] = value;
      }
    }
    return obj;
  }

  async fetch(params = {} as Record<string, any>): Promise<void> {
    let fetchParams: Record<string, any> = Object.assign(
      {},
      params,
      this.filterMap
    );
    fetchParams = this.toSnakeCaseObject(fetchParams);
    if (this.pagination.page >= 1) {
      // Ignore invalid page: <= 0
      fetchParams.page = this.pagination.page;
    }
    if (this.pagination.limit > 0) {
      fetchParams.limit = this.pagination.limit;
    }
    try {
      const data = await this.listAPI.fetch(fetchParams);
      this.setPagination(data.pagination);
      this.loadData(data.data);
    } catch (error) {
      Toast.open("Data gagal dimuat.");
    }
  }

  abstract getInstance(json: Record<string, any>): PlainModel;

  loadData(jsonArr: Array<Record<string, any>>): void {
    for (const json of jsonArr) {
      // Cek apakah data sudah ada di-list, agar tidak muncul vue duplicate key error
      if (!this.idSet.has(json.id)) {
        this.list.push(this.getInstance(json));
        this.idSet.add(json.id);
      }
    }
  }

  private resetList(): void {
    this.list.splice(0, this.list.length);
    this.idSet = new Set();
  }

  reset(): void {
    this.resetList();
    this.pagination.reset();
  }

  async onPageChange(page: number): Promise<void> {
    // debugger;
    if (!this.infiniteScroll) {
      // this.reset();
      this.resetList();
    }
    this.isLoading = true;
    if (page === 1) {
      // TODO: Perbaiki trick ini!!!!
      // Trick agar pertama kali fetch jumlah datanya memenuhi layar.
      this.pagination.limit = this.infiniteScroll
        ? APP_CONFIG.paginationFirstPageLimit
        : APP_CONFIG.paginationLimit;
    }
    // this.pagination.prevPage = this.pagination.page;
    this.pagination.page = page;
    await this.fetch();
    if (page === 1) {
      if (
        this.getList().length > APP_CONFIG.paginationLimit &&
        this.infiniteScroll
      ) {
        // TODO: Perbaiki trick ini!!!!
        // Trick agar pertama kali fetch jumlah datanya memenuhi layar.
        this.pagination.page = 2;
        this.pagination.limit = APP_CONFIG.paginationLimit;
      } else if (
        !this.infiniteScroll &&
        this.pagination.total > this.pagination.limit
      ) {
        this.pagination.nextPage = this.pagination.page + 1;
      }
    }
    this.isLoading = false;
  }

  applyFilter(filterMap?: Record<string, string>): void {
    this.reset();
    this.filtered = true;
    if (filterMap !== undefined) {
      const cleanFilterMap: Record<string, string> = {};
      for (const field of Object.keys(this.filterMap)) {
        cleanFilterMap[field] = "";
      }
      // reset filterMap
      Object.assign(this.filterMap, cleanFilterMap);
      // Tidak bisa pakai object assign, karena
      // bisa ada field-field (dynamic) lain selain q di filterMap
      for (const [field, val] of Object.entries(filterMap)) {
        Vue.set(this.filterMap, field, val);
      }
      // Object.assign(listVMRef.filterMap, filterMap);
    }
    this.onPageChange(1);
  }

  nextPage(): void {
    const newPage = this.pagination.page + 1;
    this.pagination.startRowNum = this.pagination.page * this.pagination.limit + 1;
    if (this.pagination.startRowNum + this.pagination.limit > this.pagination.total) {
      this.pagination.nextPage = 0;
    } else {
      this.pagination.nextPage = newPage + 1;
    }
    this.pagination.prevPage = this.pagination.page;
    this.pagination.page = newPage;
    // this.pagination.startRowNum = (newPage - 1) * this.pagination.limit + 1;
    // await this.fetch();
    this.onPageChange(newPage);
  }

  prevPage(): void {
    const newPage = this.pagination.page - 1;
    this.pagination.prevPage = newPage - 1;
    this.pagination.page = newPage;
    this.pagination.nextPage = newPage + 1;
    this.pagination.startRowNum = newPage * this.pagination.limit;
    // this.pagination.startRowNum = (newPage - 1) * this.pagination.limit + 1;
    // await this.fetch();
    if (newPage > 0) {
      this.onPageChange(newPage);
    }
  }

  // async nextPage() {
  //   const newPage = this.pagination.page + 1;
  //   this.pagination.prevPage = this.pagination.page;
  //   this.pagination.page = newPage;
  //   this.pagination.startRowNum = (newPage - 1) * this.pagination.limit + 1;
  //   await this.fetch();
  // }
}
