import { API } from "@/apps/core/api";
import APP_CONFIG from "@/apps/core/modules/config";
import coreState from "@/apps/core/modules/store";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import { resetState as resetPengelolaState } from "@/apps/pengelola/modules/store";
import { resetState as resetCicilanState } from "@/apps/pinjaman/modules/stores/cicilan";
import { resetState as resetPengajuanState } from "@/apps/pinjaman/modules/stores/pengajuan";
import { resetState as resetPenarikanState } from "@/apps/tabungan/modules/stores/penarikan";
import { resetState as resetSetoranState } from "@/apps/tabungan/modules/stores/setoran";
import { resetState as resetSetoranMandiriState } from "@/apps/tabungan/modules/stores/setoranMandiri";

export class TokenPair {
  refreshToken: string;
  accessToken: string;

  constructor(refreshToken = "", accessToken = "") {
    this.refreshToken = refreshToken;
    this.accessToken = accessToken;
  }
}

export class Me extends PlainModel {
  // semua harus bisa null, karena ketika direset akan menjadi null
  refreshToken: string | null = null;
  accessToken: string | null = null;
  pengelola: Record<string, any> | null = null;
  nasabah: Record<string, any> | null = null;
  username: string | null = null;
  group: string | null = null;
  tglLahir: string | null = null;
  permissions: Array<string> | null = null;

  constructor() {
    super();
    this.setFieldInitialValues();
  }

  setToken(tokenPair: TokenPair): void {
    this.refreshToken = tokenPair.refreshToken;
    this.accessToken = tokenPair.accessToken;
    localStorage.setItem("refreshToken", tokenPair.refreshToken);
    localStorage.setItem("accessToken", tokenPair.accessToken);
  }

  getResetData(): Record<string, any> {
    const blank = super.getResetData();
    // exclude refreshToken and accessToken!
    delete blank.refreshToken;
    delete blank.accessToken;
    return blank;
  }

  resetListVM(): void {
    resetPengelolaState();
    resetCicilanState()
    resetPenarikanState();
    resetPengajuanState();
    resetSetoranMandiriState();
    resetSetoranState();
  }

  logout(): void {
    this.reset();
    this.setFieldInitialValues();
    this.setToken(new TokenPair());
    coreState.reset();
    this.resetListVM();
    // TODO: Perlu hapus semua data yang di-cache
    // Reactive state, semua component yang di-keep-alive, dll
  }

  setFieldInitialValues(): void {
    this.refreshToken = localStorage.getItem("refreshToken") || "";
    this.accessToken = localStorage.getItem("accessToken") || "";
    this.group = APP_CONFIG.groupEnum.anonymous;
  }
}

export class MeAPI extends API {
  static modelPath = "/akun/me/";

  constructor() {
    super(MeAPI.modelPath);
  }

  getAPIUrl(): string {
    // hilangkan slash terakhir dari url
    return super.getAPIUrl().slice(0, -1);
  }
}

export default class MeVM extends ViewModel {
  constructor(me: Me) {
    super(new MeAPI(), me);
  }

  async fetch(): Promise<void> {
    // Override karena:
    // - pola url yang berbeda (tidak pakai pk)
    // - error akan dihandle di router.beforeEach
    const record = await this.api.fetch("");
    this.instance.loadData(record);
    // console.log(this.instance);
  }
}
