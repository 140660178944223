import Vue from "vue";
import VueRouter, {
  Location,
  NavigationGuardNext,
  Route,
  RouteConfig,
} from "vue-router";
import accountRoutes from "@/apps/accounts/modules/router";
import APP_CONFIG from "./apps/core/modules/config";
import coreRoutes from "@/apps/core/modules/router";
import MeVM, { Me } from "./apps/accounts/models/me"
import laporanRoutes from "@/apps/laporan/modules/router";
import nasabahRoutes from "@/apps/nasabah/modules/router";
import pinjamanRoutes from "@/apps/pinjaman/modules/router";
import pengelolaRoutes from "@/apps/pengelola/modules/router";
import produkRoutes from "@/apps/produk/modules/router";
import tabunganRoutes from "@/apps/tabungan/modules/router";
import stateRef, {
  hasUserId,
  isAuthenticated,
  isNewNasabahUser,
} from "./apps/accounts/modules/store";

Vue.use(VueRouter);

const notFoundRoutes = [
  {
    path: "*",
    name: "not-found",
    meta: { status: "not-found" },
  },
];

let routes: Array<RouteConfig> = [];

routes = routes.concat(
  accountRoutes,
  coreRoutes,
  laporanRoutes,
  nasabahRoutes,
  pengelolaRoutes,
  pinjamanRoutes,
  produkRoutes,
  tabunganRoutes,
  notFoundRoutes // harus di paling terakhir!!!
);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    document.getElementById("app")?.scrollIntoView();
  },
  routes,
});

function checkPermissions(to: Route, next: NavigationGuardNext) {
  // console.log(stateRef.me.group, to.meta.groups);
  let hasGroup = true;
  // console.log(stateRef.me.group);
  if (to.meta && "groups" in to.meta) {
    hasGroup = to.meta.groups.includes(stateRef.me.group);
  }

  // console.log(to.meta.permissions);
  // console.log(stateRef.me.permissions);
  let hasPerms = true;
  if (to.meta && "permissions" in to.meta) {
    const userPerms = stateRef.me.permissions ?? [];
    const appPerms = to.meta.permissions;
    hasPerms = appPerms.every((perm: string) => userPerms.includes(perm));
  }

  if (to.meta && !(hasGroup && hasPerms)) {
    to.meta.status = "forbidden";
  }
  // console.log(next);
  next();
}

router.beforeEach((to, _from, next) => {
  // console.log("router.beforeEach");
  // console.log(_from.fullPath);
  // console.log(to.fullPath);
  for (const path of APP_CONFIG.publicPages) {
    if (to.path.match(`^${path}$`) != null) {
      next();
      return;
    }
  }
  if (!isAuthenticated.value) {
    const nextOption: Location = { name: "login" };
    if (to.name !== "root") {
      nextOption.query = { next: to.path };
    }
    next(nextOption);
  } else {
    if (isAuthenticated.value) {
      if (!hasUserId.value) {
        const meVM = new MeVM(stateRef.me as Me);
        meVM
          .fetch()
          .then(() => {
            checkPermissions(to, next);
          })
          .catch((error) => {
            if (typeof error.response == "undefined" && to.meta) {
              to.meta.status = "offline";
              next();
            }
          });
      } else if (isNewNasabahUser.value) {
        if (to.name === "donor-add") {
          // diperlukan agar tidak infinite-loop
          next();
        } else {
          // User adalah donor yang belum isi data diri
          const nextOption: Location = { name: "donor-add" };
          next(nextOption);
        }
      } else {
        checkPermissions(to, next);
      }
    } else {
      checkPermissions(to, next);
    }
  }
});

export default router;
