import ListAPI from "@/apps/core/api/list";
import { Pengajuan, PengajuanAPI } from "./pengajuan";
import ViewListModel from "@/apps/core/models/viewListModel";

class PengajuanListAPI extends ListAPI {
  constructor() {
    super(PengajuanAPI.modelPath);
  }
}

class PengajuanListVM extends ViewListModel {
  constructor() {
    super(new PengajuanListAPI());
  }

  getInstance(json: Record<string, any>): Pengajuan {
    const pengajuan = new Pengajuan();
    pengajuan.loadData(json);
    return pengajuan;
  }
}

export { PengajuanListAPI };
export default PengajuanListVM;
