import { SnackbarProgrammatic as Snackbar } from "buefy";
import APP_CONFIG from "./config";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const useUpdateNotification = () => {
  let registration: ServiceWorkerRegistration | null = null;
  let refreshing = false;

  const refreshApp = () => {
    // https://medium.com/@dougallrich/give-users-control-over-app-updates-in-vue-cli-3-pwas-20453aedc1f2
    // Protect against missing registration.waiting.
    if (!registration || !registration.waiting) return;
    registration.waiting.postMessage("skip-waiting");
  };

  const showUpdateNotification = () => {
    Snackbar.open({
      message: "Tersedia versi baru.",
      type: "is-warning",
      actionText: "Update",
      indefinite: true,
      onAction: () => {
        refreshApp();
      },
    });
  };

  const updateAvailable = (event: Event) => {
    registration = (event as CustomEvent<ServiceWorkerRegistration>).detail;
    if (!registration || !registration.waiting) return;
    showUpdateNotification();
  };

  const observeUpdateEvent = () => {
    // Listen for swUpdated CustomEvent from Service Worker registration
    document.addEventListener("swUpdated", updateAvailable, { once: true });

    if (APP_CONFIG.dev) return;
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    });
  };

  return {
    observeUpdateEvent,
  };
};

export default useUpdateNotification;
