/* Pinjaman Store */

import { BaseState } from "@/apps/core/modules/store";
import { reactive } from "@vue/composition-api";
import PengajuanFM from "../../models/pengajuanForm";
import PengajuanListVM from "../../models/pengajuanList";

class PengajuanState extends BaseState {
  agree = false;
  produk: string | null = null;
  produkName: string | null = null;
  pengajuanFM: PengajuanFM | null = null;

  reset() {
    this.agree = false;
    this.produk = null;
    this.produkName = null;
    this.pengajuanFM = null;
  }
}

// State
const pengajuanState = reactive(new PengajuanState());

const listVM = new PengajuanListVM();

// Mutations
const setRefreshRequest = (requestBy: string): void => {
  listVM.setRefreshRequest(requestBy);
};

const resetState = (): void => {
  pengajuanState.reset();
  listVM.reset();
};

export {
  pengajuanState,
  listVM,
  resetState,
  setRefreshRequest,
};
