/* Produk Router */

import { RouteConfig } from "vue-router";
// import APP_CONFIG from "@/apps/core/modules/config";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";

const baseName = "produk";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "penarikan",
        name: "penarikan-list",
        component: () =>
          import(/* webpackChunkName: "penarikan" */ "../../tabungan/views/PenarikanList.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["tabungan.view_penarikan"],
        },
      },
    ],
  },
];

export default routes;
