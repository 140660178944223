import { computed, reactive } from "@vue/composition-api";
import APP_CONFIG from "@/apps/core/modules/config";
import { Me } from "@/apps/accounts/models/me";

const state: Record<"me", Me> = { me: new Me() };

// State
const stateRef = reactive(state);
export default stateRef;

// Mutations
const setMe = (me: Me): Me => (state.me = me);

// Getters
const isAuthenticated = computed(() => !!state.me.refreshToken);
const hasUserId = computed(() => !!state.me.id);
const isAdmin = computed(() => state.me.group === APP_CONFIG.groupEnum.admin);

const isPengelola = computed(() => {
  return (
    state.me.group === APP_CONFIG.groupEnum.pengelola && !!state.me.pengelola?.id
  );
});

const isNasabah = computed(() => {
  return state.me.group === APP_CONFIG.groupEnum.nasabah && !!state.me.nasabah?.id;
});

const isNewNasabahUser = computed(() => {
  return state.me.group === APP_CONFIG.groupEnum.nasabah && !state.me.nasabah?.id;
});

const setInfoNasabahId = (id: string | null): void => {
  if (state.me.nasabah) {
    state.me.nasabah.infoNasabahId = id;
  }
};

export {
  isAuthenticated,
  hasUserId,
  isAdmin,
  isPengelola,
  isNasabah,
  isNewNasabahUser,
  setInfoNasabahId,
  setMe,
};
