import { ToastProgrammatic as Toast } from "buefy";
import { API } from "../api";
import PlainModel from "./plainModel";

export default abstract class ViewModel {
  api: API;
  instance: PlainModel;
  isLoading = false;

  constructor(api: API, instance: PlainModel) {
    this.api = api;
    this.instance = instance;
  }

  reset(): void {
    this.instance.reset();
  }

  async fetch(id: string, params = {} as Record<string, string>): Promise<void> {
    try {
      const record = await this.api.fetch(id, params);
      this.instance.loadData(record);
    } catch (error) {
      Toast.open("Data gagal dimuat.");
    }
  }
}
