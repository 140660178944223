











import Vue from "vue";
import {
  computed,
  ComputedRef,
  defineComponent,
  reactive,
} from "@vue/composition-api";
import Buefy from "buefy";
import FontAwesomeIcon from "@/apps/core/modules/fontAwesome";
import Photoswipe from "vue-pswipe";
import accountStateRef, { isAdmin } from "@/apps/accounts/modules/store";
import router from "@/router";
import setAxiosInterceptors from "./axiosInterceptors";
import coreState from "./apps/core/modules/store";
import useUpdateNotification from "@/apps/core/modules/useUpdateNotification";

Vue.component("fa", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "fa",
  defaultIconPack: "fas",
});

Vue.use(Photoswipe);

const COMPONENT_MAP: Record<string, string> = {
  about: "plain-layout",
  "change-password": "plain-layout",
  default: "main-app-layout",
  forbidden: "generic-http-status",
  login: "plain-layout",
  "not-found": "generic-http-status",
  offline: "generic-http-status",
  "reset-password": "plain-layout",
  "request-reset-password": "plain-layout",
  signup: "plain-layout",
  undefined: "main-app-layout",
};

const MESSAGE_MAP: Record<string, string> = {
  offline: "Tidak bisa terhubung dengan server (Offline).",
  "not-found": "Page Not Found.",
  forbidden: "Permission Denied.",
  login: "",
  "reset-password": "",
  "request-reset-password": "",
  "change-password": "",
  default: "",
  undefined: "",
};

setAxiosInterceptors();

export default defineComponent({
  name: "App",
  components: {
    MainAppLayout: () => import("@/apps/core/layouts/MainAppLayout.vue"),
    PlainLayout: () => import("@/apps/core/layouts/PlainLayout.vue"),
    GenericHttpStatus: () => import("@/apps/core/views/GenericHttpStatus.vue"),
  },
  setup() {
    const routeRef = reactive(router);

    const status: ComputedRef<string> = computed(() => {
      const route = routeRef.currentRoute;
      if (route.meta && "status" in route.meta) {
        return route.meta.status;
      }

      // TODO: Kode if ini perlu direview, apakah diperlukan?
      if (route.meta && "objectPerm" in route.meta) {
        const objPerm = route.meta.objectPerm;
        if ("allowAdmin" in objPerm) {
          if (objPerm.allowAdmin && isAdmin.value) {
            return "default";
          }
        }

        if ("idField" in objPerm) {
          const paramsId = route.params[objPerm.idField];
          if (accountStateRef.me.id === paramsId) {
            return "default";
          }
        }
        return "forbidden";
      }

      return "default";
    });

    const { observeUpdateEvent } = useUpdateNotification();
    observeUpdateEvent();

    window.addEventListener(
      "resize",
      () => (coreState.screenWidth = window.innerWidth)
    );

    return {
      // Computed
      message: computed(() => MESSAGE_MAP[status.value]),
      dynamicComponent: computed(() => COMPONENT_MAP[status.value]),
      header: computed(() => (status.value === "offline" ? "WARNING" : "INFO")),
      compClass: computed(() =>
        status.value !== "default" ? status.value : ""
      ),
    };
  },
});
