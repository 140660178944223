import ListAPI from "@/apps/core/api/list";
import { Cicilan, CicilanAPI } from "./cicilan";
import ViewListModel, { RefreshRequest } from "@/apps/core/models/viewListModel";

class CicilanListAPI extends ListAPI {
  constructor() {
    super(CicilanAPI.modelPath);
  }
}

class CicilanListVM extends ViewListModel {
  constructor(
    refreshRequest = new RefreshRequest(),
    infiniteScroll = true) {
    super(new CicilanListAPI(), refreshRequest, infiniteScroll);
  }

  getInstance(json: Record<string, any>): Cicilan {
    const cicilan = new Cicilan();
    // console.log(json);
    cicilan.loadData(json);
    return cicilan;
  }
}

export { CicilanListAPI };
export default CicilanListVM;
