import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";


class Cicilan extends PlainModel {
  created: string | null = null;
  nilaiPokok: number | null = null;
  bunga: number | null = null;
  num: number | null = null;
  denda: number | null = null;
  subTotal: number | null = null;
  sisa: number | null = null;
  sisaPokok: number | null = null;
  sisaBayarAwal: number | null = null;
  bukti: string | null = null;
  pinjaman: Record<string, string> | null = null;
}

class CicilanAPI extends API {
  static modelPath = "/pinjaman/cicilan/";

  constructor() {
    super(CicilanAPI.modelPath);
  }
}


export { Cicilan, CicilanAPI };