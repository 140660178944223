import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import { Nasabah } from "@/apps/nasabah/models/nasabah";
import { Pinjaman } from "@/apps/pinjaman/models/pinjaman";


class SetoranMandiri extends PlainModel {
  created: string | null = null;
  nasabah: Nasabah | null = null;
  wajib: number | null = null;
  sukarela: number | null = null;
  status: string | null = null;
  sukarelaMonth: number | null = null;
  nilaiSs: number | null = null;
  pinjaman: Pinjaman | string | null = null;  // Untuk pelunasan pinjaman
  total: number | null = null;
  bukti: string | null = null;
  catatan: string | null = null;
  cicilan: Array<Record<string, any>> | null = null;
}

class SetoranMandiriAPI extends API {
  static modelPath = "/tabungan/setoran-mandiri/";

  constructor() {
    super(SetoranMandiriAPI.modelPath);
  }
}


export { SetoranMandiri, SetoranMandiriAPI };