/* Pinjaman Router */

import { RouteConfig } from "vue-router";
// import APP_CONFIG from "@/apps/core/modules/config";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";
import APP_CONFIG from "@/apps/core/modules/config";

const baseName = "pinjaman";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "",
        name: `${baseName}-list`,
        component: () =>
          import(/* webpackChunkName: "pinjaman" */ "../views/PinjamanList.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["pinjaman.view_pinjaman"],
        },
      },
      {
        path: "produk",
        name: `${baseName}-produk-list`,
        component: () =>
          import(/* webpackChunkName: "produk" */ "../../produk/views/ProdukList.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["produk.view_produk"],
        },
      },
      {
        path: "berkas",
        name: "pengajuan-berkas",
        component: () =>
          import(/* webpackChunkName: "pengajuan" */ "../views/PengajuanBerkasForm.vue"),
        meta: {
          groups: ["admin", "nasabah"],
          permissions: ["pinjaman.add_pengajuan"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/cicilan`,
        name: `${baseName}-cicilan`,
        component: () =>
          import(/* webpackChunkName: "pinjaman" */ "../views/RiwayatAngsuranDetail.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["pinjaman.view_cicilan"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/cicilan/${APP_CONFIG.nasabahIdURLPattern}`,
        name: `pengelola-${baseName}-cicilan`,
        component: () =>
          import(/* webpackChunkName: "pinjaman" */ "../views/RiwayatAngsuranDetail.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["pinjaman.view_cicilan"],
        },
      },
      {
        path: "syarat",
        name: `${baseName}-syarat`,
        component: () =>
          import(/* webpackChunkName: "pinjaman" */ "../views/SyaratPinjaman.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["pinjaman.view_pengajuan"],
        },
      },
      {
        path: `ajukan/${APP_CONFIG.idURLPattern}`,
        name: `${baseName}-ajukan`,
        component: () =>
          import(/* webpackChunkName: "pinjaman" */ "../views/AjukanPinjamanForm.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["pinjaman.add_pengajuan"],
        },
      },
      {
        path: `pengajuan/${APP_CONFIG.idURLPattern}`,
        name: "pengajuan-detail",
        component: () =>
          import(/* webpackChunkName: "pinjaman" */ "../views/PengajuanDetail.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["pinjaman.view_pengajuan", "pinjaman.view_pinjaman"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-ubah`,
        props: true,
        component: () =>
          import(/* webpackChunkName: "pinjaman" */ "../views/PerubahanPinjamanForm.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["pinjaman.add_pengajuan"],
        },
      },
    ],
  },
];

export default routes;
