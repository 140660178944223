import { reactive } from "@vue/composition-api";

abstract class BaseState {
  abstract reset(): void;
}

class CoreState extends BaseState {
  isOnline = true;
  nextUrl = "";
  screenWidth = window.innerWidth;

  reset(): void {
    this.isOnline = true;
    this.nextUrl = "";
    this.screenWidth = window.innerWidth;
  }
}

// State
const state = reactive(new CoreState());

export default state;

export { BaseState };
