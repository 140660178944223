/* Pengelola Store */
import AnggotaListVM from "../models/anggotaList";



// State

const listVM = new AnggotaListVM();

// Mutations
const setRefreshRequest = (requestBy: string): void => {
  listVM.setRefreshRequest(requestBy);
};

const resetState = (): void => {
  listVM.reset();
};

export {
  listVM,
  resetState,
  setRefreshRequest,
};
