/* Nasabah Router */

import { RouteConfig } from "vue-router";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";
import APP_CONFIG from "@/apps/core/modules/config";

const baseName = "nasabah";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "home",
        name: `${baseName}-home`,
        component: () =>
          import(/* webpackChunkName: "nasabah" */ "../views/NasabahHome.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["nasabah.view_nasabah"],
        },
      },
      {
        path: "me",
        name: `${baseName}-me`,
        component: () =>
          import(/* webpackChunkName: "nasabah" */ "../views/NasabahMe.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["nasabah.view_nasabah"],
        },
      },
      {
        path: "add",
        name: `${baseName}-add`,
        props: { headerText: "Tambah Nasabah" },
        component: () =>
          import(/* webpackChunkName: "nasabah" */ "../views/NasabahForm.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["nasabah.add_nasabah"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/summary-penutupan`,
        name: "summary-penutupan",
        component: () =>
          import(/* webpackChunkName: "nasabah" */ "../views/SummaryPenutupanDetail.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["nasabah.view_nasabah"],
        },
      },
      {
        path: APP_CONFIG.idURLPattern,
        name: `${baseName}-detail`,
        component: () =>
          import(/* webpackChunkName: "nasabah" */ "../views/NasabahMe.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["nasabah.view_nasabah"],
        },
      },
      {
        path: "ubah",
        name: `${baseName}-change`,
        props: { headerText: "Ubah Anggota" },
        component: () =>
          import(/* webpackChunkName: "nasabah" */ "../views/NasabahForm.vue"),
        meta: {
          groups: ["admin", "nasabah"],
          permissions: ["nasabah.change_nasabah"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/ubah`,
        name: "anggota-change",
        props: { headerText: "Ubah Profil" },
        component: () =>
          import(/* webpackChunkName: "nasabah" */ "../views/NasabahForm.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["nasabah.change_nasabah"],
        },
      },
      {
        path: "shu",
        name: "shu-list",
        props: { headerText: "SHU" },
        component: () =>
          import(/* webpackChunkName: "nasabah" */ "../views/SHUList.vue"),
        meta: {
          groups: ["admin", "nasabah"],
          permissions: ["nasabah.view_nasabah"],
        },
      },
    ],
  },
];

export default routes;
