import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import { Nasabah } from "@/apps/nasabah/models/nasabah";


class Setoran extends PlainModel {
  nasabah: Nasabah | null = null;
  nilai: number | null = null;
  saldoSimpanan: number | null = null;
  saldoTotal: number | null = null;
  jenis: string | null = null;
  catatan: string | null = null;
  bukti: string | null = null;
  created: string | null = null;
  // mandiri: Record<string, any> | null = null;
}

class SetoranAPI extends API {
  static modelPath = "/tabungan/setoran/";

  constructor() {
    super(SetoranAPI.modelPath);
  }

  // async fetch(): Promise<Record<string, any>> {
  //   const url = this.getAPIUrl();
  //   const response = await axios.get(url);
  //   // error akan di-catch di viewmodel / form!!!
  //   return response.data;
  // }
}

export default class SetoranVM extends ViewModel {
  constructor() {
    super(new SetoranAPI(), new Setoran());
  }
}


export { Setoran, SetoranAPI };