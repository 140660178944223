/* Setoran Router */

import { RouteConfig } from "vue-router";
// import APP_CONFIG from "@/apps/core/modules/config";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";

const baseName = "setoran";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "",
        name: `${baseName}-list`,
        component: () =>
          import(/* webpackChunkName: "setoran" */ "../views/SetoranList.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["tabungan.view_setoran"],
        },
      },
      {
        path: "mandiri-list",
        name: `${baseName}-mandiri-list`,
        component: () =>
          import(/* webpackChunkName: "setoran mandiri" */ "../views/SetoranMandiriList.vue"),
        meta: {
          groups: ["admin", "pengelola", "nasabah"],
          permissions: ["tabungan.view_setoranmandiri"],
        },
      },
      {
        path: "mandiri",
        name: `${baseName}-mandiri`,
        component: () =>
          import(/* webpackChunkName: "setoran mandiri" */ "../views/SetoranMandiriForm.vue"),
        meta: {
          groups: ["admin", "nasabah"],
          permissions: ["tabungan.add_setoranmandiri"],
        },
      },
      {
        path: "pelunasan",
        name: `${baseName}-pelunasan`,
        component: () =>
          import(/* webpackChunkName: "setoran pelunasan" */ "../views/SetoranPelunasanForm.vue"),
        meta: {
          groups: ["admin", "nasabah"],
          permissions: ["tabungan.add_setoranmandiri"],
        },
      },
      {
        path: "penarikan/add",
        name: "penarikan-add",
        component: () =>
          import(/* webpackChunkName: "penarikan" */ "../views/PenarikanForm.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["tabungan.add_penarikan"],
        },
      },
    ],
  },
];

export default routes;
