/* Laporan Router */

import { RouteConfig } from "vue-router";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";
import APP_CONFIG from "@/apps/core/modules/config";
// import APP_CONFIG from "@/apps/core/modules/config";

const baseName = "laporan";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "potongan",
        name: "potongan-gaji",
        component: () =>
          import(/* webpackChunkName: "laporan" */ "../views/PotonganGajiList.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["laporan.view_potongangaji"],
        },
      },
      {
        path: `${baseUrl}/saham`,
        name: `${baseName}-saham`,
        component: () =>
          import(/* webpackChunkName: "saham" */ "../views/BulanSahamList.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["laporan.view_potongangaji"],
        },
      },
      {
        path: `${baseUrl}/shu`,
        name: `${baseName}-shu`,
        component: () =>
          import(/* webpackChunkName: "shu" */ "../views/ShuList.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["laporan.view_shu"],
        },
      },
      {
        path: `${baseUrl}/saham/${APP_CONFIG.idURLPattern}/simpanan`,
        name: `${baseName}-saham-simpanan`,
        component: () =>
          import(/* webpackChunkName: "saham" */ "../views/BulanSahamSimpanan.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["laporan.view_bulansahamsimpanan"],
        },
      },
      {
        path: `${baseUrl}/saham/${APP_CONFIG.idURLPattern}/pinjaman`,
        name: `${baseName}-saham-pinjaman`,
        component: () =>
          import(/* webpackChunkName: "saham" */ "../views/BulanSahamPinjaman.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["pinjaman.view_cicilan"],
        },
      },
      {
        path: `${baseUrl}/laba-rugi`,
        name: `${baseName}-laba-rugi-detail`,
        component: () =>
          import(/* webpackChunkName: "laporan" */ "../views/LabaRugiDetail.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["laporan.view_laporanlabarugi"],
        },
      },
      {
        path: `${baseUrl}/laba-rugi/tambah`,
        name: `${baseName}-laba-rugi-add`,
        component: () =>
          import(/* webpackChunkName: "laporan" */ "../views/LabaRugiForm.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["laporan.add_laporanlabarugi"],
        },
      },
      {
        path: `${baseUrl}/neraca`,
        name: `${baseName}-neraca-detail`,
        component: () =>
          import(/* webpackChunkName: "laporan" */ "../views/NeracaDetail.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["laporan.view_neraca"],
        },
      },
      {
        path: `${baseUrl}/neraca/tambah`,
        name: `${baseName}-neraca-add`,
        component: () =>
          import(/* webpackChunkName: "laporan" */ "../views/NeracaForm.vue"),
        meta: {
          groups: ["admin", "pengelola"],
          permissions: ["laporan.add_neraca"],
        },
      }
    ],
  },
];

export default routes;
