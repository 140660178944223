import ListAPI from "@/apps/core/api/list";
import { Penarikan, PenarikanAPI } from "./penarikan";
import ViewListModel from "@/apps/core/models/viewListModel";

class PenarikanListAPI extends ListAPI {
  constructor() {
    super(PenarikanAPI.modelPath);
  }
}

class PenarikanListVM extends ViewListModel {
  constructor() {
    super(new PenarikanListAPI());
  }

  getInstance(json: Record<string, any>): Penarikan {
    const penarikan = new Penarikan();
    penarikan.loadData(json);
    return penarikan;
  }
}

export { PenarikanListAPI };
export default PenarikanListVM;
