import axios from "axios";
import { APIBase } from ".";

export default abstract class ListAPI extends APIBase {
  async fetch(
    params = {} as Record<string, any>
  ): Promise<Record<string, any>> {
    const url = this.apiUrl;
    const response = await axios.get(url, { params: params });
    return response.data;
  }
}

export class GenericListAPI extends ListAPI {
  options: Array<Record<string, string>> = [];

  constructor(modelPath: string) {
    super(modelPath);
  }

  resetOptions(): void {
    this.options.splice(0, this.options.length);
  }

  async fetch(
    params = {} as Record<string, any>
  ): Promise<Array<Record<string, any>>> {
    this.resetOptions();
    try {
      const json = await super.fetch(params);
      json.data.forEach((el: Record<string, string>) => {
        this.options.push(el);
      });
    } catch (error) {
      this.resetOptions();
      return [];
    }
    return this.options;
  }
}
