import ListAPI from "@/apps/core/api/list";
import { Setoran, SetoranAPI } from "./setoran";
import ViewListModel from "@/apps/core/models/viewListModel";

// class SetoranInterface extends PlainModel {
//   nasabah: Nasabah | null = null;
//   setoran: Setoran | null = null;
//   created: string | null = null;
//   mandiri: SetoranMandiri | null = null;
// }

class SetoranListAPI extends ListAPI {
  constructor() {
    super(SetoranAPI.modelPath);
  }
}

class SetoranListVM extends ViewListModel {
  constructor() {
    super(new SetoranListAPI());
  }

  getInstance(json: Record<string, any>): Setoran {
    const setoran = new Setoran();
    setoran.loadData(json);
    // if (setoranInterface.setoran) {
    //   const setoran = new Setoran();
    //   setoran.loadData(setoranInterface.setoran);
    // } else if (setoranInterface.mandiri) {
    //   const setoran = new SetoranMandiri();
    //   setoran.loadData(setoranInterface.mandiri);
    // }
    return setoran;
  }
}

export { SetoranListAPI };
export default SetoranListVM;
