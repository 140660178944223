






import { defineComponent } from "@vue/composition-api";
import APP_CONFIG from "../modules/config";
import { hasUserId } from "@/apps/accounts//modules/store";

export default defineComponent({
  setup() {
    return {
      exclude: new RegExp(APP_CONFIG.keepAliveExcludePattern),
      hasUserId,
    };
  },
});
