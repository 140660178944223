const DEV = false;

let API_DOMAIN_NAME, API_PORT, API_HOST, BASE_API_URL;

if (DEV) {
  API_DOMAIN_NAME = "192.168.2.11";
  API_PORT = "8000";
  API_HOST = API_DOMAIN_NAME + ":" + API_PORT;
  BASE_API_URL = "http://" + API_HOST;
} else {
  API_DOMAIN_NAME = "styapi.kspapp.id";
  API_HOST = API_DOMAIN_NAME;
  BASE_API_URL = "https://" + API_HOST;
}

const BASE_S3_URL = "https://koperasisty.s3.amazonaws.com";

const ID_REGEX_PATTERN =
  "([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})";

enum GROUP_ENUM {
  anonymous = "anonymous",
  admin = "admin",
  nasabah = "nasabah",
  pengelola = "pengelola",
}

const RESIZE_WIDTH = 800;
const RESIZE_HEIGHT = 800;
const RESIZE_QUALITY = 0.8;

const MONTHS = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const TGL_CUTOFF = 18;

const APP_CONFIG = Object.freeze({
  dev: DEV,
  apiHOST: API_HOST,
  baseS3Url: BASE_S3_URL,
  brandText: "Koperasi Bina Kasih",
  subBrandText: "Your Blood Can Save Lifes",
  baseAPIURL: BASE_API_URL,
  groupEnum: GROUP_ENUM,
  idURLPattern: `:id${ID_REGEX_PATTERN}`,
  keepAliveExcludePattern: "(.*?)(Home|Update|Detail|Create|Form|Me|Scan)",
  mobileScreenWidth: 768,
  months: MONTHS,
  nasabahIdURLPattern: `:nasabahId${ID_REGEX_PATTERN}`,
  publicPages: [
    "/about",
    "/daftar",
    "/login",
    `/reset-password/${ID_REGEX_PATTERN}/`,
    "/request-reset-password",
  ],
  paginationLimit: 10,
  paginationFirstPageLimit: 20,
  resizeHeight: RESIZE_HEIGHT,
  resizeQuality: RESIZE_QUALITY,
  resizeWidth: RESIZE_WIDTH,
  tglCutoff: TGL_CUTOFF,
  textLimit: 15,
});

export default APP_CONFIG;
