import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";


class Anggota extends PlainModel {
  nama: string | null = null;
  email: string | null = null;
  noHp: string | null = null;
  tipeUser: string | null = null;
  unitKerjaId: string | null = null;
  statusKaryawan: string | null = null;
}

class AnggotaAPI extends API {
  static modelPath = "/pengelola/anggota/";

  constructor() {
    super(AnggotaAPI.modelPath);
  }
}

export default class AnggotaVM extends ViewModel {
  constructor() {
    super(new AnggotaAPI(), new Anggota());
  }
}


export { Anggota, AnggotaAPI };