import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faAddressCard,
  faAngleLeft,
  faAngleRight,
  // faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBan,
  faBed,
  faBook,
  faBriefcaseMedical,
  faBullhorn,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  // faChevronUp,
  faCloudUploadAlt,
  faDollarSign,
  faMoneyCheck,
  faMoneyCheckAlt,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faFileExcel,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilter,
  // faFolderPlus,
  faGift,
  faHandHoldingUsd,
  faHome,
  faImage,
  faInfo,
  faInfoCircle,
  // faImages,
  // faLabel,
  faKey,
  faListAlt,
  faLock,
  faMars,
  faMap,
  faMinus,
  faPhoneAlt,
  faPlus,
  faPlusSquare,
  faPowerOff,
  faSave,
  faSchool,
  faSearch,
  faShare,
  faSignInAlt,
  faSignOutAlt,
  faSlidersH,
  faStar,
  faStethoscope,
  faSyringe,
  // faSyncAlt,
  faTable,
  faTag,
  faTasks,
  faTimes,
  faTimesCircle,
  faTrash,
  faUpload,
  faUserTie,
  faUserMd,
  faUserNurse,
  faUser,
  faUsers,
  faVenus,
  faVideo,
  // faUserSlash,
  faWalking,
  faWallet,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAddressCard,
  faAngleLeft,
  faAngleRight,
  // faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBan,
  faBed,
  faBook,
  faBriefcaseMedical,
  faBullhorn,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  // faChevronUp,
  faCloudUploadAlt,
  faDollarSign,
  faCircle,
  faMoneyCheck,
  faMoneyCheckAlt,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faFileExcel,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilter,
  // faFolderPlus,
  faGift,
  faHandHoldingUsd,
  faHome,
  faImage,
  faInfo,
  faInfoCircle,
  // faImages,
  // faLabel,
  faKey,
  faListAlt,
  faLock,
  faMars,
  faMap,
  faMinus,
  faPhoneAlt,
  faPlus,
  faPlusSquare,
  faPowerOff,
  faSave,
  faSchool,
  faSearch,
  faShare,
  faSignInAlt,
  faSignOutAlt,
  faSlidersH,
  faStar,
  faStethoscope,
  faSyringe,
  // faSyncAlt,
  faTable,
  faTag,
  faTasks,
  faTimes,
  faTimesCircle,
  faTrash,
  faUpload,
  faUserTie,
  faUserMd,
  faUserNurse,
  faUser,
  faUsers,
  faVenus,
  faVideo,
  // faUserSlash,
  faWalking,
  faWallet,
  faWrench
);


export default FontAwesomeIcon;
