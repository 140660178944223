import ListAPI from "@/apps/core/api/list";
import { SetoranMandiri, SetoranMandiriAPI } from "./setoranMandiri";
import ViewListModel from "@/apps/core/models/viewListModel";

class SetoranMandiriListAPI extends ListAPI {
  constructor() {
    super(SetoranMandiriAPI.modelPath);
  }
}

class SetoranMandiriListVM extends ViewListModel {
  constructor() {
    super(new SetoranMandiriListAPI());
  }

  getInstance(json: Record<string, any>): SetoranMandiri {
    const setoranMandiri = new SetoranMandiri();
    setoranMandiri.loadData(json);
    return setoranMandiri;
  }
}

export { SetoranMandiriListAPI };
export default SetoranMandiriListVM;
