import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import { Nasabah } from "@/apps/nasabah/models/nasabah";
import { Pinjaman } from "./pinjaman";


class Pengajuan extends PlainModel {
  created: string | null = null;
  nasabah: Nasabah | null = null;
  prevPinjaman: string | null = null;
  tipe: string | null = null;
  pinjaman: Pinjaman | null = null;
  produk: Record<string, any> | null = null;
  nilai: number | null = null;
  angsuran: number | null = null;
  tenor: number | null = null;
  status: string | null = null;
  approval: string | null = null;
  approvedBy: Record<string, string> | null = null;
  catatan: string | null = null;
  dokList: Array<Record<string, string>> | null = null;

  setPinjaman(pinjaman: Pinjaman): void {
    this.pinjaman = pinjaman;
  }
}

class PengajuanAPI extends API {
  static modelPath = "/pinjaman/pengajuan/";

  constructor() {
    super(PengajuanAPI.modelPath);
  }
}


class PengajuanValidateAPI extends API {
  static modelPath = "/pinjaman/pengajuan/validate/";

  constructor() {
    super(PengajuanValidateAPI.modelPath);
  }
}

export default class PengajuanVM extends ViewModel {
  constructor() {
    super(new PengajuanAPI(), new Pengajuan());
  }
}


export { Pengajuan, PengajuanAPI, PengajuanValidateAPI };